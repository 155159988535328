@font-face {
  font-family: 'OrbitronLight';
  src: url('../Font/orbitron-light.ttf');
}
@font-face {
  font-family: 'KomikaAxisRegular';
  src: url('../Font/komika_axis/KOMIKAX_.ttf');
}

.djx-text {
  margin-top: 5px;
  /* background-image: linear-gradient(to right, #E5CE0B, #C45B07, orange); */
  color: #FFF0E8;
	font-family: 'KomikaAxisRegular';
	text-shadow: 0 -1px 4px #FFFFFF, 0 -2px 10px #FFDD00, 
			0 -10px 20px #ff8000, 0 -15px 20px red;

  font-size: 50px;
}
.react-notification-root {
  margin-top: 40px !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.social-media-img{
  width: 75px;
  height: 60px;
  margin-top: -10px;
  transition: 0.5s;

}
.social-media-img:hover{
  opacity: 0.7;
  transition: 0.5s;
}
#djx-img {
  /* width: 200px; */
  position: absolute;
  top: 20px;
  z-index: 0;

}

#djx-img img {
  height: 500px;
  width: auto;
}

.glyphicon-pause {
  opacity: 1 !important;
}

/* ===================== Event ================ */
/* .slide-container {
  margin: auto;
  width: 600px;
  text-align: center;
} */
/* .slide-container {
  margin: auto;
  width: 600px;
  text-align: center;
} */
#wrapper {
  padding: 70px 0px 70px 0px;
  margin-left: 120px !important;
}

.clash-card {
  background: white;
  width: 300px;
  display: inline-block;
  margin: auto;
  border-radius: 19px;
  position: relative;
  text-align: center;
  box-shadow: -1px 15px 30px -12px white;
  /* z-index: 99; */
}

#fixedbutton {
  position: fixed;
  bottom: 0px;
  right: 0px;
  margin-bottom: 20px;
  margin-right: 10px;
  opacity: 0.5;
  background: transparent;
  border: none;
  outline: none;
  z-index: 9999;
  cursor: pointer;
}

#fixedbutton:hover {
  opacity: 1;
}

.request-css {
  cursor: pointer !important;
}

/* .clash-card__image {
  position: relative;
  height: 230px;
  margin-bottom: 35px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
} */

.clash-card__level--barbarian {
  color: #EC9B3B;
}

.clash-card__level {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 3px;
}

.clash-card__unit-name {
  font-size: 26px;
  color: black;
  font-weight: 900;
  margin-bottom: 5px;
}

.clash-card__unit-description {
  padding: 20px;
  margin-bottom: 10px;
}





/* ========================== End Event ================= */


.button-4 {
  background-color: transparent;
  border: 3px solid #0602ff;
  color: #1559eb;
  transition: .3s;
}

.button-4:hover {
  animation: pulse 1s infinite;
  transition: .3s;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  70% {
    transform: scale(.8);
  }

  100% {
    transform: scale(1);
  }
}

/* ============ Radio ===================== */
/* audio:hover, audio:focus, audio:active
{
-webkit-box-shadow: 15px 15px 20px rgba(0,0, 0, 0.4);
-moz-box-shadow: 15px 15px 20px rgba(0,0, 0, 0.4);
box-shadow: 15px 15px 20px rgba(0,0, 0, 0.4);
-webkit-transform: scale(1.05);
-moz-transform: scale(1.05);
transform: scale(1.05);
}
audio
{
-webkit-transition:all 0.5s linear;
-moz-transition:all 0.5s linear;
-o-transition:all 0.5s linear;
transition:all 0.5s linear;
-moz-box-shadow: 2px 2px 4px 0px #006773;
-webkit-box-shadow:  2px 2px 4px 0px #006773;
box-shadow: 2px 2px 4px 0px #006773;
-moz-border-radius:7px 7px 7px 7px ;
-webkit-border-radius:7px 7px 7px 7px ;
border-radius:7px 7px 7px 7px ;
} */
/* ============= Radio End =============== */
html {
  scroll-behavior: smooth;
}

.djx {

  margin-top: -5px;
  background-image: linear-gradient(to right, #E5CE0B, #C45B07, orange);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: 22px !important;


}

.play-button {
  color: rgb(22, 181, 209);
  font-family: 'OrbitronLight';
  text-shadow: 0 0 0px #A5F1FF, 0 0 0px #A5F1FF,
    0 0 0px #A5F1FF, 0 0 30px #A5F1FF,
    0 0 0px #A5F1FF;
  font-size: 22px !important;
  margin-top: -5px;
}

.display-5 {
  font-size: 78px;
  /* color: #444444 !important;
	font-family: 'MEgalopolisExtraRegular';
	text-transform: uppercase;
  text-shadow: -1px -1px 1px #000000, 1px 1px 1px #CCCCCC; */
  /* color: #FFF0E8;
	font-family: 'KomikaAxisRegular';
	text-shadow: 0 -1px 4px #FFFFFF, 0 -2px 10px #FFDD00, 
      0 -10px 20px #ff8000, 0 -15px 20px red; */
  color: #D0F8FF;
  font-family: 'OrbitronLight';
  text-shadow: 0 0 5px #A5F1FF, 0 0 10px #A5F1FF,
    0 0 20px #A5F1FF, 0 0 30px #A5F1FF,
    0 0 40px #A5F1FF;
  /* color: #FFFFFF;
	-webkit-mask-box-image: url(mask.png) 0 0 repeat; */

}

.background-transfarent {
  background-color: transparent;
}

.fontsize-22 {
  font-size: 22px;
}

.gallary-img {
  height: 500px;
  width: 60%;
  margin-left: 20%;
  text-align: center;
}

/* .navbar{
  position: sticky !important;
  top:0 !important;
  z-index: 20;
} */
/* swiper slider */
.swiper-container {
  height: auto;
}

.swiper-wrapper {
  /* align-items: center !important; */
  text-align: center;
}

#swiper-slide-video1 {
  width: 400px !important;
  height: 300px !important;
  margin-left: 50px !important;
}

#swiper-slide-video1 img {
  width: 400px;
  height: 200px;
}

#swiper-slide-video1 .info a {
  margin-top: -35px;
  border: none !important;
  font-size: 30px;
}

.swiper-slide {
  width: 200px !important;
  /* height: 250px; */
  border-radius: 7px;

}

.swiper-slide img {
  /* border:0.5px solid black; */
  height: 200px;
  background-color: #040308;
  width: 200px;
  /* height: 250px; */
  border-radius: 10px 10px 0 0;


}
#catagarynav{
  max-width: 100%;
  /* overflow-y: hidden; */
  margin-bottom: 30px;
  overflow-x: scroll;
  overflow-y: hidden;
  /* scroll-snap-type:  mandatory;
  white-space: nowrap; */
}
/* #catagarynav::-webkit-scrollbar{
  height: 4px;
  width: 4px;
  background: black;
}
#catagarynav::-webkit-scrollbar-button {
  background-color: red;

} */
#catagarynav::-webkit-scrollbar {
  width: 12px;
}

/* Track */
#catagarynav::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
#catagarynav::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255,0,0,0.6); 
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}
#catagarynav::-webkit-scrollbar-thumb:window-inactive {
background: rgba(255,0,0,0.4); 
}
/* #catagarynav::-webkit-scrollbar {
  width: 12px;
}

#catagarynav::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  border-radius: 10px;
}

#catagarynav::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
} */
.swiper-slide-active {
  transform: scale(1.2);
  transition: .4s;
}

.info {
  width: 100%;
  padding: 8px 0;
  background: rgba(248, 246, 246, 0.9);
  position: relative;
  bottom: 0;
  text-align: justify;
  border-radius: 0 0 5px 5px;

}

.info h5 {
  font-size: 15px;
  margin-left: 7px;
  font-weight: 100;
  color: black;

  
}
#music nav ul li a:hover{
  cursor: pointer;
}
.info p {
  font-size: 10px;
  margin-left: 7px;
  color: black;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 130px;
  text-transform: capitalize;
}

.swiper-slide .info a {
  text-decoration: none;
  color: black;
  float: right;
  margin-right: 7px;
  margin-top: -50px;
  /* border: 1px solid black; */
  border-radius: 20px;
  font-size: 10px;
  padding: 2px 8px;
  /* background-color: #EEEEEE; */
}

.swiper-slide .info a:hover {
  cursor: pointer;
}

.footer-link {
  text-decoration: none !important;
}

/* end slider */
body {
  /* font-family: 'Baloo Da 2', cursive; */
  background-color: black;
}

.social-media {
  border-radius: 50%;
  font-weight: 500;
  border: 3px solid #DC3545;
  transition: 0.2s linear;
  width: 60px;
  text-align: center;
  padding: 5px;
  /* border: 2px solid red; */
}

.social-media:hover {
  opacity: 0.8;
  /* font-size: 20px; */
  transition: 0.2s linear;
}

/* ================ radio button ==================== */
/* .radio-toolbar {
  margin: 10px;
} */

.radio-toolbar input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}



.radio-toolbar label {
  display: inline-block;
  background-color: #ddd;
  padding: 5px 20px;
  font-family: sans-serif, Arial;
  font-size: 16px;
  border: 2px solid #444;
  border-radius: 4px;
}

.radio-toolbar label:hover {
  background: -webkit-linear-gradient(left, #FFDD00 0%, #ff8000 100%);
  opacity: 0.8;
}

.radio-toolbar input[type="radio"]:focus+label {
  border: 2px dashed #444;
}

.radio-toolbar input[type="radio"]:checked+label {
  color: black;
  /* text-shadow: 0 0 5px #A5F1FF, 0 0 10px #A5F1FF, 
			0 0 20px #A5F1FF, 0 0 30px #A5F1FF, 
      0 0 40px #A5F1FF; */
  /* text-shadow: 0 -1px 4px #FFFFFF, 0 -2px 10px #FFDD00, 
      0 -1px 20px #ff8000, 0 -1px 20px red; */
  /* background-color: rgb(194, 113, 113); */
  background: -webkit-linear-gradient(left, #FFDD00 0%, #ff8000 100%);
  border-color: #ff8000;

  font-weight: 900;

}

/* ================ End Radio button =============== */

/* .lnr {
  font-family: linearicons-free;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.lnr-menu:before {
    content: "\e871";
} */

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#menu {
  z-index: 99999;
}

.qwsmoothscroll {
  color: white !important;
  padding: 5px 20px !important;
  font-size: 22px;
  transform: translate3d(0, 0, 0);
  /* position: relative; */
  /* line-height: 70px; */
  /* border-bottom:2px solid red; */
  transition: .5s;
  /* position:sticky;
    top:0; */
}

.fixed {
  position: fixed;
  top: -50px;
  margin: auto;
  background-color: #040308;
  z-index: 20;
  width: 100%;
  padding: 10px auto 10px auto;
}

li .active,
li a:hover {
  background-color: red !important;
  /* line-height: normal; */
  opacity: 0.8;
  transition: .5s;
}

.sendmsg {
  cursor: pointer;
  background: -webkit-linear-gradient(left, rgb(87, 180, 96) 0%, rgb(72, 174, 167) 100%);
  color: rgb(255, 255, 255);
  /* border-radius: 30px; */
  /* padding: 10px; */
  font-size: 24px;
  opacity: 0.8;
  /* margin-top: 20px; */
}

.contactinput::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #A9A9A9;
}

.contactinput {
  border-radius: 20px;
  font-size: 20px;
}

.hSACzk {
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  /* height: 100%; */
  /* padding-bottom: 192px; */
  /* padding-top: 16px; */
  /* background-size: 100% 100%; */
  background-size: cover;
  /* background: url(../images/main-bg.jpeg); */
  /* background: url('../images/header.png'); */
  background-repeat: no-repeat;
  background-position: 50% 65%;
}

.gGcadR {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  z-index: 1;
  flex-flow: column;
  /* margin-top: -500px !important; */
}

.kNNzPp {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-flow: column nowrap;
  margin-top: 30px;
  z-index: 111;
}

.boluTB {
  font-size: 60px;
  /* font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol"; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-weight: 700;
  /* line-height: 55px; */
  /* letter-spacing: -0.04ch; */
  text-transform: none;
  color: black;
  text-align: center;
  display: block;
  font-variant-ligatures: no-common-ligatures;
  margin: 0px;
  padding: 0px;
  margin-top: 0px !important;
}

.maxwidth1 {
  max-width: 668px;

}

#header .form-control:focus {
  box-shadow: none;
}

.heading {
  color: black;
  font-weight: 900;
}

.py-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.mylink {
  color: red;
  font-size: 20px;
}

.mylink:hover {
  color: red;
  font-size: 20px;

}

.img-150 {
  width: 150px;
}

.description-font {
  font-size: 20px;
}

.heading-font {
  font-size: 32px;
}

.hgwMYc {
  font-size: 14px;
  font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.04ch;
  text-transform: none;
  color: rgb(0, 23, 26);
  display: block;
  font-variant-ligatures: no-common-ligatures;
  margin: 0px;
  padding: 0px;
}

.bXtOlf {
  font-size: 24px;
  font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.04ch;
  text-transform: none;
  color: rgb(0, 23, 26);
  display: block;
  font-variant-ligatures: no-common-ligatures;
  margin: 0px;
  padding: 0px;
}

.contactbtn {

  min-height: 32px;

  -webkit-box-pack: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  text-align: center;
  background-color: rgb(231, 231, 231);
  box-shadow: transparent 0px 0px 0px 1px inset;

  border-radius: 1000px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  transition: background-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  text-decoration: none;
  /* background-color: #E7E7E7;
    color: black;
    font-weight: 900;
    border-radius: 50px; */
}

.contactbtn:hover {
  background-color: #E7E7E7;
  color: #6287C7;
}

.shop_container {
  position: relative;
}

.list_view .product_img {
  float: left;
  width: 30%;
}

.rating {
  /* background-image: url(../images/rate_star.png); */
  background-position: 0 -13px;
  background-repeat: repeat-x;
  /* font-size: 34px; */
  height: 13px;
  transition: all 0.5s ease-out 0s;
  -webkit-transition: all 0.5s ease-out 0s;
  width: 80px;
}

.product_rate {
  /* background-image: url(../images/rate_star.png); */
  background-position: 0 0;
  background-repeat: repeat-x;
  height: 13px;
  font-weight: 900;
}

/* .imglogo{
    background-image: url('../images/logo1.png');
  } */
.product_head {
  font-size: 18px;
  font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.04ch;
  text-transform: none;
  color: rgb(25, 25, 25);
  display: block;
  font-variant-ligatures: no-common-ligatures;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
}

.product_details {
  font-size: 16px;
  font-family: 'Baloo Da 2', cursive;
  /* font-family: 'Baloo Chettan 2', cursive; */
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0ch;
  text-transform: none;
  color: rgb(118, 118, 118);
  display: block;
  font-variant-ligatures: no-common-ligatures;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;

  overflow: hidden;
  /* color:#767676;
    line-height: 1;
    font-size: 18px; */
}

.boxshadow:hover {
  /* background-color:yellow; */
  /* width:200px;
    height:100px; */
  /* transform: 2s; */
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: 1s;
  /* box-shadow:50px 50px 100px 0px black; */
}

.logomargin {
  margin-left: 280px;
  /* background-image: url('../images/50x50.png'); */
}

/* .stringdot{
    display:inline-block;
    width:80%;
    overflow:hidden !important;
  } */
.bSRnlq {
  max-width: 100%;
}

.eFwXTH {
  font-size: 18px;
  font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.04ch;
  text-transform: none;
  color: rgb(25, 25, 25);
  display: block;
  font-variant-ligatures: no-common-ligatures;
  margin: 0px;
  padding: 0px;
}

.kHghik {
  font-size: 14px;
  font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0ch;
  text-transform: none;
  color: rgb(118, 118, 118);
  display: block;
  font-variant-ligatures: no-common-ligatures;
  margin: 0px;
  padding: 0px;
}

.image-wrap {
  /* max-height: 95%;*/
  overflow: hidden;
  cursor: pointer;
  /* max-width: 800px;        */
  /* -webkit-transition: .5s ease-out;  
    
    transition:  .5s ease-out; */
}

.image-wrap img {
  /* object-fit: cover; */
  width: 100%;
  height: 100%;
  /* position: absolute; */
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  border-radius: 4px;
}

.navbarstirk {
  overflow: hidden;
  position: fixed;
  z-index: 999;
  top: 0;
  width: 100%;
  background-color: white;
}

.hWGisA {
  max-width: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: flex-start;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

#navbar a {
  font-size: 16px;
  font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.04ch;
  text-transform: none;
  color: rgb(25, 25, 25);
  display: block;
  font-variant-ligatures: no-common-ligatures;

  /* text-align: center;
      text-overflow: ellipsis;
      width: 100%;
      white-space: nowrap;
      overflow: hidden; */
}

#navbar .active {
  color: rgb(235, 23, 0);
  -webkit-box-align: center;
  align-items: center;
  cursor: default;
  display: flex;
  height: 100%;
  width: auto;
  animation: 0.2s ease 0s 1 normal none running bNMfGa;
  border-bottom: 4px solid rgb(235, 23, 0);
  padding: 0px 4px;
}

.xdlgy {
  font-size: 16px;
  font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0ch;
  text-transform: none;
  color: rgb(118, 118, 118);
  display: block;
  font-variant-ligatures: no-common-ligatures;
  margin: 0px;
  padding: 0px;
}

/* @media only screen and (min-width: 576px) {
    .image-wrap { min-width: 576px and max-width: 1222px; }
  } */
/* ========================= side bar ---------------------- */

.sidenav {
  height: 100%;
  width: 0px;
  position: fixed;
  z-index: 9999;
  top: 0;
  /* margin-bottom: 30px; */
  left: 0;
  background-color: #FFFFFF;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 10px;
  /* padding-top: 60px; */
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 18px;
  font-weight: 700;
  line-height: 0px;
  color: black;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: red;
}

.sidenav .closebtn {
  position: relative;
  max-width: 100%;
  display: inline-flex;
  min-height: 40px;
  cursor: pointer;
  user-select: none;
  text-align: center;
  background-color: rgb(255, 255, 255);
  box-shadow: transparent 0px 0px 0px 1px inset;
  width: 40px;
  height: 40px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  /* margin: 0px; */
  border-radius: 40px;
  margin-bottom: 30px;
  border-style: none;

  transition: background-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  text-decoration: none;

  font-size: 30px;
  margin-left: 25px;
  /* fill: rgb(73, 73, 73);
      height: 24;
      width: 24; */
  /* position:sticky; */
  /* margin-top: -80px; */
  /* margin-left: 30px;
    font-size: 36px;
    cursor: pointer;
      user-select: none;  */

}

.sidenav .closebtn:hover {
  background-color: rgb(247, 247, 247);
  box-shadow: transparent 0px 0px 0px 1px inset;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 18px;
  }
}

/* ------------------------media query ------------------------------ */
@media (min-width: 1200px) {
  .mr-50 {
    margin-right: -40px;
  }

  .ml-50 {
    margin-left: -40px;
  }
}

@media (min-width: 991.98px) and (max-width: 1200px) {
  .description-font {
    font-size: 16px;
  }

  .heading-font {
    font-size: 26px;
  }

}

@media (min-width: 767.98px) and (max-width: 991.98px) {
  #djx-img {
    top: 30px;
    left: 0px;

  }

  .fixed {
    top: 0px;
  }

  .display-5 {
    font-size: 68px;
  }

  .hSACzk {
    padding-bottom: 50px;
    padding-top: 16px;
  }

  .kNNzPp {
    margin-top: 30px !important;
  }

  .boluTB {
    font-size: 42px;

    line-height: 40px;
    margin-top: 0px !important;
    margin: 0 10%;
    padding: 0px;

  }

  .description-font {
    font-size: 18px;
  }

  .heading-font {
    font-size: 26px;
  }
  .djx-text{
    font-size: 22px !important;
  }
}

@media (min-width: 575.98px) and (max-width: 767.98px) {

  /* #wrapper {
    margin-right: 120px;
    margin-left: 80px;
  } */
  .djx-text{
  margin-top: -10px;
    font-size: 22px;
  }
  .display-5 {
    font-size: 48px;
  }

  #swiper-slide-video1 {
    width: 300px !important;
    height: 300px !important;
    margin-left: 20px !important;
  }

  #swiper-slide-video1 img {
    width: 300px;
    height: 200px;
  }

  .gallary-img {
    width: 100%;
    margin-left: 0;
  }

  .fixed {
    top: 0px;
  }

  .hSACzk {

    background-size: auto 100%;
  }

  .logomargin {
    margin-left: auto;
  }

  .description-font {
    font-size: 18px;
  }

  .heading-font {
    font-size: 26px;
  }

  .kNNzPp {
    margin-top: 30px !important;
  }

  .boluTB {
    font-size: 42px;

    line-height: 40px;

    margin: 0 10%;

    margin-top: 0px !important;
  }
}

@media (max-width: 575.98px) {
  .djx-text{
    margin-top: -10px;
      font-size: 22px;
    }
  #djx-img {

    top: 30px;
    left: -70px;
  }

  .clash-card {
    width: 300px;
  }

  #wrapper {
    /* margin-right: 0px !important; */
    margin: 60px !important;
  }

  #swiper-slide-video1 {
    width: 200px !important;
    height: 300px !important;
    margin-left: 0px !important;
  }

  #swiper-slide-video1 img {
    width: 200px;
    height: 200px;
  }

  .gallary-img {
    width: 100%;
    margin-left: 0;
  }

  .fixed {
    top: 0px;
  }

  .description-font {
    font-size: 14px;
  }

  .heading-font {
    font-size: 20px;
  }

  .kNNzPp {
    margin-top: 30px;
  }

  .logomargin {
    margin-left: auto;
  }

  .boluTB {
    font-size: 36px;

    line-height: 40px;
    margin-top: 0px !important;
    margin: 0 10%;
  }

  .display-5 {
    font-size: 28px;
  }

  #fixedbutton {
    margin-bottom: 10px;
    margin-right: 0px !important;
  }
}

/* @media (max-width: 400px) {
  .clash-card {
    width: 260px;
      }
      #wrapper {
        margin-right: 120px !important;
        margin-left: 180px !important;
      }
} */